import 'font-awesome/css/font-awesome.min.css';
import './assets/css/app.css';
import 'react-toastify/dist/ReactToastify.css';


import DashboardPage from './pages/DashboardPage';

import LoginPage from './pages/auth/LoginPage'
import ResetPassword from './pages/auth/ResetPassword';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewPRoduct from './pages/Product/NewPRoduct';
import ViewProduct from './pages/Product/ViewProduct';
import Product from './pages/Product/Product';
import Brand from './pages/Brand/Brand';
import Category from './pages/Categories/Category';
import OrderList from './pages/OrderList';
import BrandList from './pages/Brand/BrandList';
import CategoryList from './pages/Categories/CategoryList';
import AddFlow from './pages/flows/AddFlow';
import FlowList from './pages/flows/FlowList';


import { Slider } from '@mui/material';

import EditCategory from './pages/Categories/EditCategory';
import EditBrand from './pages/Brand/EditBrand';
import EditFlow from './pages/flows/EditFlow';
import TableSetting from './pages/TableSetting';
import EditProduct from './pages/Product/EditProduct';
import UserList from './pages/User/UserList';
import User from './pages/User/User';
import Vendor from './pages/User/Vendor';
import Influencer from './pages/User/Influencer';
import UserDetail from './pages/User/UserDetail';
import AddProductVariant from './pages/ProductVariant/AddProductVariant';
import ViewProductVariant from './pages/ProductVariant/ViewProductVariant';
import AddPromo from './pages/Promos/AddPromo';
import Promolist from './pages/Promos/Promolist';
import Testing from './pages/ProductVariant/Testing';
import PrivateRoutes from './Authorization/PrivateRoutes';
import Blogs from './pages/Blogs/Blogs';
import BlogsList from './pages/Blogs/BlogsList';
import Detail from './pages/Invoice/Detail';
import Dashpage from './pages/Dashboard/Dashpage';
import Registration from './pages/Registration';
import InvoicePage from './components/InvoicePage';
import EmailVerification from './pages/EmailVerification';






function App() {
    return (
        <Router>
            <Routes>
                <Route element={<PrivateRoutes />}>

                    {/* <Route exact path='/login' element={<LoginPage />} /> */}
                    <Route exact path='/dashboard' element={<Dashpage />} />

                    <Route exact path='/reset-password' element={<ResetPassword />} />
                    <Route exact path='/profile' element={<ProfilePage />} />
                    <Route exact path='/change-password' element={<ChangePasswordPage />} />
                    <Route exact path='/preferences' element={<UserPreferencesPage />} />
                    <Route exact path='/newproduct' element={<NewPRoduct />} />
                    <Route exact path='/viewproduct' element={<ViewProduct />} />
                    <Route exact path='/product' element={<Product />} />
                    <Route exact path='/brand' element={<Brand />} />



                    <Route exact path='/brandlist' element={<BrandList />} />
                    <Route exact path='/orderlist' element={<OrderList />} />
                    <Route exact path='/category' element={<Category />} />
                    <Route exact path='/categorylist' element={<CategoryList />} />
                    <Route exact path='/flow' element={<AddFlow />} />
                    <Route exact path='/flowlist' element={<FlowList />} />



                    <Route exact path='/userlist' element={<User />} />
                    <Route exact path='/vendorlist' element={<Vendor />} />
                    <Route exact path='/influencer' element={<Influencer />} />
                    <Route exact path='/addpromo' element={<AddPromo />} />
                    <Route exact path='/promolist' element={<Promolist />} />
                    <Route exact path='/testing' element={<Testing />} />
                    <Route exact path='/productdetail' element={<Detail />} />


                    < Route path="/single_category" element="">
                        <Route path=':id' element={<EditCategory />} />
                    </Route>
                    <Route path="/single_brand" element="">
                        <Route path=':id' element={<EditBrand />} />
                    </Route>
                    <Route path="/single_flow" element="">
                        <Route path=':id' element={<EditFlow />} />
                    </Route>
                    <Route path="/product_single" element="">
                        <Route path=':id' element={<EditProduct />} />
                    </Route>
                    <Route path="/addvariant" element="">
                        <Route path=':id' element={< AddProductVariant />} />
                    </Route>

                    <Route path="/userDetail" element="">
                        <Route path=':id' element={<UserDetail />} />
                    </Route>


                    <Route path="/viewvariant" element="">
                        <Route path=':id' element={<ViewProductVariant />} />
                    </Route>

                    <Route path="/editvariant" element="">
                        <Route path=':id' element={<AddProductVariant />} />
                    </Route>



                    <Route path="/editpromo" element="">
                        <Route path=':id' element={<AddPromo />} />
                    </Route>


                    <Route exact path='/blogs' element={<Blogs />} />
                    <Route path="/editblogs" element="">
                        <Route path=':id' element={<Blogs />} />
                    </Route>
                    <Route exact path='/blogslist' element={<BlogsList />} />
                    <Route exact path='/orderlist/:unique' element={<OrderList />} />
                </Route>


                {/* <Route exact path='/tablesetting' element={<TableSetting />} /> */}
                <Route >
                    <Route exact path='/' element={<LoginPage />} />
                    <Route exact path='/invoicepage/:id' element={<InvoicePage />} />

                    <Route exact path='/registration' element={<Registration />} />
                    <Route exact path='/email-verification/:unique/:id' element={<EmailVerification />} />


                </Route>
            </Routes>



        </Router>
    )
}

export default App;

