// import axios from 'axios';
// import React, { useEffect, useRef, useState } from 'react'
// import { useLocation, useParams, useSearchParams } from 'react-router-dom'
// import { BaseUrl } from '../utils/Token';
// import logo from "../assets/images/logo.png"
// import { removeBackslashes } from '../pages/OrderDetail';
// import moment from 'moment/moment';
// import { useReactToPrint } from 'react-to-print';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
// import html2pdf from "html2pdf.js";

// function InvoicePage() {

//     const invoiceRef = useRef(null);
//     const { id } = useParams()

//     let token = localStorage.getItem("token")
//     const [data, setdata] = useState([])
//     const [salesdata, setsalesdata] = useState([])

//     useEffect(() => {
//         if (data.sale && data.sale !== "undefined") {
//             try {
//                 let saledata = removeBackslashes(data.sale);
//                 saledata = JSON.parse(saledata);
//                 setsalesdata(saledata);
//                 console.log(saledata)
//             } catch (error) {
//                 console.error("Error parsing sales data:", error);
//             }
//         }
//     }, [data]);

//     const headers = {
//         Authorization: `Bearer ${token}`
//     }

//     const fetchData = async () => {
//         try {
//             const response = await axios.get(`${BaseUrl}/invoice_list?_id=${id}`, {
//                 headers,
//             });
//             //   setUser(response.data.data);

//             setdata(response.data?.data[0])



//         } catch (error) {
//             console.error("Error fetching category data:", error);
//         }
//     };



//     useEffect(() => {
//         fetchData()
//     }, [])


//     const calculateTotals = (maindata) => {
//         let Subtotal = 0;

//         // let totalQty = 0;

//         // let totalNetPayable = 0;


//         maindata?.forEach((item) => {
//             Subtotal += parseInt(item.product_variant.mrp) || 0;

//         });

//         return {
//             Subtotal,
//         };
//     };

//     const totals = calculateTotals(salesdata);



//     const handlePrint = useReactToPrint({
//         content: () => invoiceRef.current,
//     });



//     const handleDownloadPdf = () => {
//         const content = invoiceRef.current;

//         const pdfOptions = {
//             margin: 0,
//             filename: "invoice.pdf",
//             image: { type: "jpeg", quality: 0.98 },
//             html2canvas: { scale: 1 },
//             jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
//         };
//         // content.insertAdjacentHTML("beforeend", `<style>${pdfStyles}</style>`);
//         html2pdf().from(content).set(pdfOptions).save();
//     };


//     return (
//         <>
//             <section className="" ref={invoiceRef}>
//                 <div className="container">
//                     <div className="row justify-content-center">
//                         <div className="col-12 col-lg-9 col-xl-8 col-xxl-7">
//                             <div className="row gy-3 mb-3" >
//                                 <div className="col-6">
//                                     <h2 className="text-uppercase text-endx m-0">Invoice</h2>
//                                 </div>
//                                 <div className="col-6">
//                                     <a className="d-block text-end" href="#!">
//                                         <img src={logo} className="img-fluid" alt="BootstrapBrain Logo" width="135" height="44" />
//                                     </a>
//                                 </div>
//                                 <div className="col-md-6">
//                                     <h4>From</h4>
//                                     <address>
//                                         <h5><strong>{data?.vendor?.username}</strong></h5>
//                                         <p>{data?.vendor?.phone}</p>
//                                         <p>{data?.vendor?.email}</p>
//                                     </address>
//                                 </div>
//                                 <div className="col-md-6  ">
//                                     <div className="text-md-end">
//                                         <h4>Bill To</h4>
//                                         <address>
//                                             <h5><strong>{data?.user?.username}</strong></h5>
//                                             <p>{data?.user?.phone}</p>
//                                             <p>{data?.user?.email}</p>
//                                             <p className='fw-bold'>Invoice Date : {moment(Date.now()).format("MM-DD-YYYY")}</p>
//                                         </address>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row mb-3">
//                                 <div className="col-12">
//                                     <div className="table-responsive">
//                                         <table className="table table-striped">
//                                             <thead>
//                                                 <tr>
//                                                     <th scope="col" className="text-uppercase">Qty</th>
//                                                     <th scope="col" className="text-uppercase">Product</th>
//                                                     <th scope="col" className="text-uppercase text-end"></th>
//                                                     <th scope="col" className="text-uppercase text-end">Amount</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody className="table-group-divider">
//                                                 {salesdata && salesdata.map((item) => {
//                                                     return (
//                                                         <>
//                                                             <tr>
//                                                                 <th scope="row">{item?.qty}</th>
//                                                                 <td>{item?.product_variant?.product?.long_name}</td>
//                                                                 <td className="text-end"></td>
//                                                                 <td className="text-end">{item?.product_variant?.mrp}</td>
//                                                             </tr>
//                                                         </>
//                                                     )
//                                                 })}

//                                                 <tr>
//                                                     <td colSpan="3" className="text-end">Subtotal</td>
//                                                     <td className="text-end">{totals?.Subtotal}</td>
//                                                 </tr>
//                                                 {/* <tr>
//                                                     <td colSpan="3" className="text-end">VAT (5%)</td>
//                                                     <td className="text-end">18.1</td>
//                                                 </tr> */}
//                                                 {/* <tr>
//                                                     <td colSpan="3" className="text-end">Shipping</td>
//                                                     <td className="text-end">15</td>
//                                                 </tr> */}
//                                                 <tr>
//                                                     <th scope="row" colSpan="3" className="text-uppercase text-end">Total</th>
//                                                     <td className="text-end">{totals?.Subtotal}</td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="row">
//                                 <div className="col-12 ">
//                                     <div className="d-flex gap-3 justify-content-center">
//                                         <button type="submit" className="btn bg-primary mb-3 text-white" onClick={handleDownloadPdf} >Download Invoice</button>
//                                         <button type="submit" className="btn bg-danger text-white mb-3" onClick={handlePrint}>Print Invoice</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     )
// }

// export default InvoicePage



import React, { useEffect, useRef, useState } from "react";
import "./Invoice.css";
import logo from "../assets/images/logo.png";
import { useLocation, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";

import axios from "axios";

import moment, { now } from "moment/moment";
import { BaseUrl } from "../utils/Token";
import html2pdf from "html2pdf.js";
import { removeBackslashes } from "./RemoveBacklashes";




function InvoicePage() {


    const componentRef = useRef(null);
    const { id } = useParams()



    let token = localStorage.getItem("token")

    const headers = {
        Authorization: `Bearer ${token}`
    }
    const [data, setdata] = useState([])
    const [salesdata, setsalesdata] = useState([])

    useEffect(() => {
        if (data.sale && data.sale !== "undefined") {
            try {
                let saledata = removeBackslashes(data.sale);
                saledata = JSON.parse(saledata);
                setsalesdata(saledata);
                console.log(saledata)
            } catch (error) {
                console.error("Error parsing sales data:", error);
            }
        }
    }, [data]);



    // Add explicit styles
    const pdfStyles = `
.tableview th, .tableview td {
    padding: 10px; // Add the desired padding
}
// Add more styles as needed
`;







    const handleDownload = () => {
        const content = componentRef.current;

        const pdfOptions = {
            margin: 10,
            filename: "invoice.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 1 },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        // Apply styles to the PDF content
        content.insertAdjacentHTML("beforeend", `<style>${pdfStyles}</style>`);

        html2pdf(content, pdfOptions);
    };

    const calculateTotals = (data) => {
        let Subtotal = 0;


        salesdata?.forEach((item) => {
            Subtotal += parseInt(item.product_variant.mrp) || 0;

        });

        return {

            Subtotal,
        };
    };

    const totals = calculateTotals(salesdata);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/invoice_list?_id=${id}`, {
                headers,
            });
            //   setUser(response.data.data);

            setdata(response.data?.data[0])



        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };



    useEffect(() => {
        fetchData()
    }, [])


    return (
        <>
            <div class="tm_container" id="invoicepage">
                <div class="tm_invoice_wrap" ref={componentRef}>
                    <div class="tm_invoice tm_style1" id="tm_download_section">
                        <div class="tm_invoice_in">
                            <div class="tm_invoice_head tm_align_center tm_mb20">
                                <div class="tm_invoice_left">
                                    <div class="tm_logo">
                                        <img src={logo} width={200} alt="Logo" />
                                    </div>
                                </div>
                                <div class="tm_invoice_right tm_text_right">
                                    <div class="tm_primary_color tm_f30 tm_text_uppercase">
                                        Invoice
                                    </div>
                                </div>
                            </div>
                            <div class="tm_invoice_info tm_mb20">
                                <div class="tm_invoice_seperator tm_gray_bg"></div>
                                <div class="tm_invoice_info_list">
                                    <p class="tm_invoice_number tm_m0">
                                        Invoice No:{" "}
                                        <b class="tm_primary_color text-uppercase">
                                            #{id?.substring(16, 24)}
                                        </b>
                                    </p>
                                    {/* <p class="tm_invoice_date tm_m0">
                                        Date:{" "}
                                        <b class="tm_primary_color">
                                            {moment(Date.now()).format("MM-DD-YYYY")}
                                        </b>
                                    </p> */}
                                </div>
                            </div>
                            <div class="tm_invoice_head tm_mb10">
                                <div class="tm_invoice_left">
                                    <h4 class="mb-0">
                                        <b class="tm_primary_color">Sold By :</b>
                                    </h4>
                                    <h5 className="mt-3 my-0"><strong>{data?.vendor?.username}</strong></h5>
                                    <p className="mb-0">{data?.vendor?.phone}</p>
                                    <p className="mb-0">{data?.vendor?.email}</p>

                                </div>
                                <div class="tm_invoice_right tm_text_right">
                                    <h4 className="mb-0">
                                        <b class="tm_primary_color">Invoice to:</b>
                                    </h4>

                                    {/* <h5><strong>{data?.user?.username}</strong></h5> */}
                                    <p className="mb-0 mt-3">{data?.user?.phone}</p>
                                    <p>{data?.user?.email}</p>


                                </div>
                            </div>




                            <div className="tableview">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {/* <th className="tm_gray_bg pdf-friendly" style={{ width: "12%" }}>Sr. no</th> */}
                                            {/* <th className="tm_gray_bg">UPC</th> */}
                                            <th className="tm_gray_bg pdf-friendly" style={{ width: "12%" }}>Item Name</th>
                                            <th className="tm_gray_bg  pdf-friendly " style={{ width: "12%" }}>Qty</th>

                                            <th className="tm_gray_bg  pdf-friendly" style={{ width: "12%" }}> Mrp</th>
                                            {/* <th className="tm_gray_bg  pdf-friendly">Tax Value</th> */}




                                        </tr>
                                    </thead>
                                    <tbody className="table-divider">
                                        {salesdata?.map((item, index) => {


                                            return (
                                                <tr key={index}>
                                                    {/* <td>{index + 1}</td> */}

                                                    <td>{item?.product_variant?.product.long_name}</td>
                                                    <td>{item?.qty}</td>
                                                    <td>{item?.product_variant.mrp}</td>






                                                </tr>
                                            );
                                        })}

                                        <tr className="" style={{ borderTop: "1px solid black" }}>
                                            <td className="fw-bold">Total</td>
                                            {/* <td></td> */}
                                            <td className="fw-bold">{ }</td>
                                            <td className="fw-bold">{totals?.Subtotal}</td>





                                        </tr>
                                    </tbody>
                                </table>
                            </div>




                        </div>
                    </div>
                    <div class="tm_invoice_btns tm_hide_print">
                        <a
                            href="javascript:window.print()"
                            class="tm_invoice_btn tm_color1"
                        >
                            <span class="tm_btn_icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="ionicon"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linejoin="round"
                                        stroke-width="32"
                                    />
                                    <rect
                                        x="128"
                                        y="240"
                                        width="256"
                                        height="208"
                                        rx="24.32"
                                        ry="24.32"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linejoin="round"
                                        stroke-width="32"
                                    />
                                    <path
                                        d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linejoin="round"
                                        stroke-width="32"
                                    />
                                    <circle cx="392" cy="184" r="24" fill="currentColor" />
                                </svg>
                            </span>
                            <span class="tm_btn_text">Print</span>
                        </a>
                        <button
                            id="tm_download_btn"
                            onClick={handleDownload}
                            class="tm_invoice_btn tm_color2"
                        >
                            <span class="tm_btn_icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="ionicon"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        d="M320 336h76c55 0 100-21.21 100-75.6s-53-73.47-96-75.6C391.11 99.74 329 48 256 48c-69 0-113.44 45.79-128 91.2-60 5.7-112 35.88-112 98.4S70 336 136 336h56M192 400.1l64 63.9 64-63.9M256 224v224.03"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="32"
                                    />
                                </svg>
                            </span>
                            <span class="tm_btn_text">Download</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default InvoicePage;





