import React, { useEffect, useRef, useState } from 'react'
import adminLayout from '../../hoc/adminLayout'
import Loader from '../../common/Loader'
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios'
import { BaseUrl, token } from '../../utils/Token'
import { toast } from 'react-toastify'



function Blogs() {



    const { id } = useParams()
    const [open, setopen] = useState(false)

    const [blogsdata, setblogsdata] = useState([])
    const [heading, setheading] = useState("")
    const [short_description, setshort_description] = useState("")
    const [desc, setdesc] = useState("")
    const [file, setfile] = useState([])
    const [image, setimage] = useState([])

    const ImageInputRef = useRef(null);

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
    };

    const handleimage = (e) => {
        e.preventDefault()
        // let selectedFile = e.target.files
        const selectedFiles = Array.from(e.target.files);
        setfile(selectedFiles)
    }

    const handleclear = () => {
        setheading("")
        setshort_description("")
        setdesc("")
        if (ImageInputRef.current) {
            ImageInputRef.current.value = '';
        }

    }

    const filterdata = () => {
        axios.get(`${BaseUrl}/blogs`, { headers }).then((res) => {
            console.log(res)
            let filter = res.data.data.filter((item) => item._id == id)
            console.log(filter)
            setheading(filter[0].heading)
            setshort_description(filter[0].short_description)
            setdesc(filter[0].description)
            setimage(filter[0].images)
        })
    }

    useEffect(() => {
        if (id) {
            filterdata()
        }
    }, [id])




    const handleSubmit = (e) => {
        e.preventDefault()
        setopen(true)
        let formdata = new FormData()
        if (id) {
            formdata.append("_id", id)
        }
        formdata.append("heading", heading);
        formdata.append("short_description", short_description);
        formdata.append("description", desc);
        if (file.length > 0) {
            file?.map((item) => {
                formdata.append("file", item);
            });
        }

        if (id) {
            axios.put(`${BaseUrl}/blogs`, formdata, { headers }).then((res) => {
                console.log(res)

                if (res.data.status == "OK") {
                    toast.success("Data Update Successfully")
                }

            }).catch((err) => {
                toast.error("Data not Submit")
            }).finally(() => {
                setopen(false)
            })
        } else {
            axios.post(`${BaseUrl}/blogs`, formdata, { headers }).then((res) => {
                console.log(res)

                if (res.data.status == "OK") {
                    handleclear()
                    toast.success("Data Submit Successfully")
                }

            }).catch((err) => {
                toast.error("Data not Submit")
            }).finally(() => {
                setopen(false)
            })
        }
    }
    return (
        <>
            <Loader open={open} />
            <section>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="bg-white box">
                                <h4 className="px-3 py-3">
                                    {id ? "Update Blog" : "Add Blog"}
                                </h4>
                                <div className="">
                                    <Form onSubmit={handleSubmit}>
                                        <Row className="p-3 productform">
                                            <Col md="4" className="mb-3">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Heading
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        value={heading}
                                                        onChange={(e) => setheading(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="4" className="mb-3">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Short Description
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required
                                                        value={short_description}
                                                        onChange={(e) => setshort_description(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="4" className="mb-3">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        File
                                                    </label>
                                                    <input
                                                        type="file"
                                                        ref={ImageInputRef}
                                                        className="form-control"

                                                        multiple
                                                        onChange={(e) => handleimage(e)}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="12" className="mb-3">
                                                <FormGroup>
                                                    <label htmlFor="" className="fw-bold">
                                                        Description
                                                    </label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={desc}
                                                        onChange={handleEditorChange}
                                                        className="custom-ckeditor"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <div className="col-md-12">
                                                {file.length > 0 ? <h3 className='mb-4'>Images</h3> : ""}
                                                <div className="row">
                                                    {file.length > 0 ? <div className="d-flex align-items-center gap-2">
                                                        {file.map((image, index) => (
                                                            <div key={index} className="image-preview">
                                                                <img
                                                                    id='image-input'
                                                                    src={URL.createObjectURL(image)}
                                                                    className="img-fluid"
                                                                    style={{ height: "100px", borderRadius: "10px" }}
                                                                    alt={`Image ${index + 1}`}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div> :
                                                        image.map((item) => {
                                                            return (
                                                                <>
                                                                    <div className="col-md-2">
                                                                        <img src={`${BaseUrl}/${item}`} className='img-fluid' alt="" />
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                            <Col md="12">
                                                <div className="">
                                                    <button class="btn bg-primary text-white">
                                                        {id ? "Update" : "Add"} Blogs
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default adminLayout(Blogs)
