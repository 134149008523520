import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Form, Row } from "react-bootstrap";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseUrl, token } from "../utils/Token";
import adminLayout from "../hoc/adminLayout";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import notfound from "../assets/images/notfound.jpg"


function OrderList() {
  const [user, setUser] = useState([]);
  const [orderstatus, setorderstatus] = useState("")
  const [loading, setloading] = useState(false)
  const navigate = useNavigate()
  const [search, setsearch] = useState("")
  const headers = {
    Authorization: `Bearer ${token}`,
  };


  const location = useLocation()

  const { unique } = useParams()

  const statuspage = location.pathname == `/orderlist/${unique}`



  useEffect(() => {
    axios.get(`${BaseUrl}/invoice_list`, { headers }).then((res) => {
      let filter = res.data.data.filter((item) => item?.top_level_order_status?.toLowerCase() == unique?.toLowerCase())

      console.log("filter", filter)
      setUser(filter)
      setsearch(unique)
    })
  }, [unique])

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${BaseUrl}/order_list`, {
  //       headers,
  //     });
  //     setUser(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching category data:", error);
  //   }
  // };

  function removeBackslashes(inputString) {
    // Check if inputString is a string, if not, return it as is
    if (typeof inputString !== 'string') {
      return inputString;
    }
    return inputString.replace(/\\/g, '');
  }


  let saledata = ""
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/invoice_list`, {
        headers,
      });
      setUser(response.data.data);






    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };





  useEffect(() => {
    if (!statuspage) {
      fetchData();
      setsearch("")
    }
  }, [statuspage]);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };







  const handledetail = (e, params) => {
    e.preventDefault()
    navigate('/productdetail', { state: params })
    console.log(params)
  }


  const handlechangeorder = (e, id, name) => {
    let value = e.target.value;
    let requestData = {
      _id: id,
      [name]: value
    };

    axios.put(`${BaseUrl}/invoice`, requestData, { headers })
      .then((res) => {
        if (res.data.status == "OK") {
          toast.success("Date Updated Successfully")
          fetchData()
        }
      })
      .catch((error) => {
        console.error('Error updating order:', error);
        toast.error("Data Not Updated")
      });
  };


  const statusOptions = [

    { value: 'Pending', label: 'Pending', color: 'warning' },
    { value: 'Processing', label: 'Processing', color: 'info' },
    { value: 'Shipped', label: 'Shipped', color: 'primary' },
    { value: 'Out For Delivery', label: 'Out For Delivery', color: 'primary' },
    { value: 'Delivered', label: 'Delivered', color: 'success' },
    { value: 'Return Request', label: 'Return Request', color: 'warning' },
    { value: 'Returned', label: 'Returned', color: 'secondary' },
    { value: 'Cancelled', label: 'Cancelled', color: 'danger' },
  ];



  const handleSearch = (e) => {
    e.preventDefault();

    let filterdata = user.filter((item) => {
      const usernameMatch = item?.vendor?.username?.toLowerCase() === search.toLowerCase();
      const phoneMatch = item?.user?.phone === search;
      const vendorphone = item?.vendor?.phone == search
      const isordersettle = item?.is_order_settled?.toLowerCase() == search.toLowerCase();
      const top_level_order_status = item?.top_level_order_status?.toLowerCase() == search.toLowerCase();


      return usernameMatch || phoneMatch || vendorphone || isordersettle || top_level_order_status;

    });

    console.log(filterdata);
    setUser(filterdata)
  };
  useEffect(() => {
    if (search?.length == 0 && !statuspage) {
      fetchData()
      // console.log("hello reset")
    }
  }, [search])



  return (
    <>
      {loading && <Loader />}
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <div className="" style={{ borderBottom: " 1px solid rgba(0, 0, 0, 0.12)" }}>
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                      <h5 className="px-3 py-3">Order List</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="me-3">
                        <Form onSubmit={(e) => handleSearch(e)}>
                          <div className="input-group searchbox rounded-3">

                            <input type="search" className="form-control" value={search} onChange={(e) => setsearch(e.target.value)} placeholder="Search by Name , Phone , Status " />
                            <button className="btn bg-primary text-white"><span className="p-2"><i class="fa-solid fa-magnifying-glass"></i></span></button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>

                </div>
                {/* <div className="">
                  <DataGrid
                    rows={reversedRows}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...user.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div> */}

                {user.length > 0 ? <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead >
                      <tr>
                        <th>User </th>
                        <th>Vendor</th>
                        <th>Commission</th>
                        <th>Items</th>
                        <th>Payable Order</th>
                        <th>Shipping Charge</th>
                        <th>Change Order Status</th>
                        <th>Change Payout Status</th>
                        <th>Invoice Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user?.reverse().map((item) => {
                        let saledata = removeBackslashes(item?.sale);
                        saledata = JSON.parse(saledata)
                        let lenghtdata = saledata.length
                        const currentStatus = statusOptions.find(option => option.value === item.top_level_order_status);

                        return (
                          <>
                            <tr>
                              <td>
                                <div>
                                  <h6 className="mb-0">Phone : <span>{item?.user?.phone}</span></h6>
                                  {item.user?.email && <h6 className="mb-0">Email : <span>{item?.user?.email}</span></h6>}

                                </div>
                              </td>
                              <td>
                                <div>
                                  <h6 className="mb-0">Name : <span>{item.vendor.username}</span></h6>
                                  <h6 className="mb-0">Phone : <span>{item.vendor.phone}</span></h6>
                                </div>
                              </td>
                              <td>
                                <h6>{item.commission}</h6>
                              </td>
                              <td>
                                <h6>{lenghtdata}</h6>
                              </td>
                              <td>
                                <h6>{item.payable_order_value}</h6>
                              </td>
                              <td>
                                <h6>{item.shipping_charge}</h6>
                              </td>
                              <td>
                                {currentStatus && (
                                  <span className={`badge px-3  rounded-pill bg-${currentStatus.color}`}>
                                    {currentStatus.label}
                                  </span>
                                )}
                              </td>
                              <td>
                                {/* <select name="" style={{ fontSize: '12px' }} onChange={(e) => handlechangeorder(e, item._id, "is_order_settled")} value={item.is_order_settled} className="form-control form-select" id="">
                                  <option value="">--Select--</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Processing">Processing</option>
                                  <option value="Settled">Settled</option>
                                </select> */}
                                <span className={`badge px-3  rounded-pill ${item.is_order_settled == "Pending" ? "bg-warning" : item.is_order_settled == "Processing" ? " bg-info" : "bg-success"} `}>
                                  {item.is_order_settled}
                                </span>
                              </td>
                              <td>
                                <button onClick={(e) => handledetail(e, item)} className="btn"><i class="fa-solid fa-circle-info"></i></button>
                              </td>
                            </tr>
                          </>
                        )
                      })}

                    </tbody>
                  </table>
                </div> :
                  <>
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-4">
                        <div className="text-center ">
                          <img src={notfound} className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(OrderList);
