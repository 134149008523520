

import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Header from "./header";
import { ToastContainer } from "react-toastify";

const Sidebar = () => {
    const [isClicked, setIsClicked] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState("");
    const location = useLocation();

    const handleClick = () => {
        setIsClicked(!isClicked);
    };

    const handleDropdownClick = (dropdownId) => {
        if (activeDropdown === dropdownId) {
            setActiveDropdown("");
            localStorage.removeItem("activeDropdown");
        } else {
            setActiveDropdown(dropdownId);
            localStorage.setItem("activeDropdown", dropdownId);
        }
    };

    useEffect(() => {
        const storedDropdown = localStorage.getItem("activeDropdown");
        setActiveDropdown(storedDropdown);
    }, []);

    useEffect(() => {
        localStorage.setItem("activeDropdown", activeDropdown);
    }, [activeDropdown]);

    const isLinkActive = (path) => {
        return location.pathname === path ? "active" : "";
    };

    const isDropdownOpen = (dropdownId) => {
        return activeDropdown === dropdownId ? "show" : "";
    };


    const handlelogout = () => {
        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        localStorage.clear();
    };

    return (
        <>
            <ToastContainer />
            <div
                className={`border-end sidenav ${isClicked ? "ml-15" : ""}`}
                id="sidebar-wrapper"
            >
                <div className="sidebar-heading border-bottom">
                    <Link to="/" className="bg-white " style={{ boxShadow: "0 0 10px #ddd" }}>
                        <img alt="Alt content" className="img-fluid logo" src={logo} />
                    </Link>
                </div>
                <PerfectScrollbar className="sidebar-items">
                    <ul className="list-unstyled ps-0">
                        <li className="my-3">

                            <a className="d-flex align-items-center"
                            >
                                <i class="fa-solid fa-house me-1"></i>
                                <Link to="/dashboard" className={`${isLinkActive("/dashboard")} singlepadding`}>
                                    <span className=""></span> Dashboard
                                </Link>
                            </a>




                        </li>
                        <li className="my-3">
                            <a
                                className={`dropdown-toggle ${isLinkActive(
                                    "/products"
                                )}`}
                                onClick={() => handleDropdownClick("productsCollapse")}
                                role="button"
                                aria-expanded={activeDropdown === "productsCollapse"}
                            >
                                <i className="fa-solid fa-box"></i> Products
                            </a>
                            <ul
                                className={`collapse pl-3 ${isDropdownOpen(
                                    "productsCollapse"
                                )}`}
                                id="productsCollapse"
                            >

                                <li>
                                    <Link
                                        to="/viewproduct"
                                        className={isLinkActive("/viewproduct")}
                                    >
                                        <span className={`submenulist `}></span> View Product
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/product"
                                        className={isLinkActive("/product")}
                                    >
                                        <span className="submenulist"></span> Add Product
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {/* <li className="my-3">
                            <a
                                className={`dropdown-toggle ${isLinkActive(
                                    "/products"
                                )}`}
                                onClick={() => handleDropdownClick("userCollapse")}
                                role="button"
                                aria-expanded={activeDropdown === "userCollapse"}
                            >
                                <i class="fa-solid fa-user me-1"></i>Users
                            </a>

                            <ul className={`collapse pl-3 ${isDropdownOpen(
                                "userCollapse"
                            )}`}
                                id="userCollapse">
                                <li>
                                    <Link to="/userlist" className={isLinkActive("/userlist")}>
                                        <span className="submenulist"></span> User List
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/vendorlist" className={isLinkActive("/vendorlist")}>
                                        <span className="submenulist"></span> Vendor List
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/influencer" className={isLinkActive("/influencer")}>
                                        <span className="submenulist"></span> Influencer List
                                    </Link>
                                </li>
                            </ul>


                        </li> */}

                        <li className="my-3">

                            <a className="d-flex align-items-center"
                            >
                                <i class="fa-brands fa-first-order me-1"></i>
                                <Link to="/orderlist" className={`${isLinkActive("/orderlist")} singlepadding`}>
                                    <span className=""></span> Invoice List
                                </Link>
                            </a>




                        </li>

                        <li className="my-3">




                            <a
                                className={`dropdown-toggle ${isLinkActive(
                                    "/brandCollapse"
                                )}`}
                                onClick={() => handleDropdownClick("brandCollapse")}
                                role="button"
                                aria-expanded={activeDropdown === "brandCollapse"}
                            >
                                <i class="fa-brands fa-bandcamp me-1"></i>Brand
                            </a>

                            <ul className={`collapse pl-3 ${isDropdownOpen(
                                "brandCollapse"
                            )}`}
                                id="brandCollapse">
                                <li>
                                    <Link tag="a" className={isLinkActive("/brand")} to="/brand">
                                        <span className="submenulist"></span> Add Brand
                                    </Link>
                                    <Link to="/brandlist" className={isLinkActive("/brandlist")}>
                                        <span className="submenulist"></span> Brand List
                                    </Link>
                                </li>


                            </ul>


                        </li>

                        <li className="my-3">
                            <a
                                className={`dropdown-toggle ${isLinkActive(
                                    "/categoryCollapse"
                                )}`}
                                onClick={() => handleDropdownClick("categoryCollapse")}
                                role="button"
                                aria-expanded={activeDropdown === "categoryCollapse"}
                            >
                                <i class="fa-solid fa-bars"></i> Category
                            </a>
                            <ul className={`collapse pl-3 ${isDropdownOpen(
                                "categoryCollapse"
                            )}`}
                                id="categoryCollapse">
                                <li>
                                    <Link tag="a" to="/category" className={isLinkActive("/category")}>
                                        <span className="submenulist"></span> Add Category
                                    </Link>
                                    <Link to="/categorylist" className={isLinkActive("/categorylist")}>
                                        <span className="submenulist"></span> Category List
                                    </Link>
                                </li>
                            </ul>

                        </li>

                        {/* <li className="my-3">



                            <a
                                className={`dropdown-toggle ${isLinkActive(
                                    "/blogscollapse"
                                )}`}
                                onClick={() => handleDropdownClick("blogscollapse")}
                                role="button"
                                aria-expanded={activeDropdown === "blogscollapse"}
                            >
                                <i class="fa-brands fa-nfc-directional"></i> Blogs
                            </a>

                            <ul className={`collapse pl-3 ${isDropdownOpen(
                                "blogscollapse"
                            )}`}
                                id="blogscollapse">
                                <li>
                                    <Link tag="a" to="/blogs" className={isLinkActive("/blogs")}>
                                        <span className="submenulist me-1"></span>Add Blogs
                                    </Link>
                                    <Link to="/blogslist" className={isLinkActive("/blogslist")}>
                                        <span className="submenulist me-1"> </span>Blogs List
                                    </Link>
                                </li>
                            </ul>


                        </li> */}

                        {/* <li className="my-3">
                            <a
                                className={`dropdown-toggle ${isLinkActive(
                                    "/promossidebar"
                                )}`}
                                onClick={() => handleDropdownClick("promosCollapse")}
                                role="button"
                                aria-expanded={activeDropdown === "promosCollapse"}
                            >
                                <i class="fa-brands fa-nfc-directional"></i> Promos
                            </a>

                            <ul className={`collapse pl-3 ${isDropdownOpen(
                                "promosCollapse"
                            )}`}
                                id="promosCollapse">
                                <li>
                                    <Link tag="a" to="/addpromo" className={isLinkActive("/addpromo")}>
                                        <span className="submenulist me-1"></span>Add Promo
                                    </Link>
                                    <Link to="/promolist" className={isLinkActive("/promolist")}>
                                        <span className="submenulist me-1"> </span>Promo List
                                    </Link>
                                </li>
                            </ul>


                        </li> */}


                    </ul>
                </PerfectScrollbar>
                <div className="dropdown fixed-bottom-dropdown">
                    <Link tag="a" className="" to="/" onClick={handlelogout}>
                        <i className="fa-solid fa-right-from-bracket me-2"></i>
                        <span>Logout</span>
                    </Link>
                </div>
            </div>
            <Header
                className={`navbar navbar-expand-lg navbar-light bg-light  fixed-top border-bottom ${isClicked ? " left-0" : ""
                    }`}
                handleClick={handleClick}
            />
        </>
    );
};

export default Sidebar;

