import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, FormGroup } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import register from "../assets/images/register.avif"
import logo from "../assets/images/logo.png"
import { BaseUrl, token } from "../utils/Token";
import { toast } from "react-toastify";
import { Country, State, City } from 'country-state-city';
import Loader from "../common/Loader";





function Registration() {

    const [name, setName] = useState("");
    const [email, setemail] = useState("");
    const [gender, setgender] = useState("");
    const [file, setfile] = useState("")


    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [phone, setphone] = useState("")
    const [accountNumber, setaccountNumber] = useState("")
    const [bankname, setbankname] = useState("")
    const [ifscCode, setifscCode] = useState("")
    const [businesstype, setbusinesstype] = useState("")

    const [selectcountry, setselectcountry] = useState("")
    const [accountHolderName, setaccountHolderName] = useState("")

    const [maindoc, setmaindoc] = useState("")
    const [firstname, setfirstname] = useState("")
    const [lastname, setlastname] = useState("")
    const [address, setaddress] = useState("")
    const [registeraddress, setregisteraddress] = useState("")


    const [alternatedoc, setalternatedoc] = useState("")


    const [icfile, seticfile] = useState("")

    const headers = {
        Authorization: `Bearer ${token}`
    }

    const [country, setcountry] = useState([])


    useEffect(() => {
        setcountry(Country.getAllCountries())
    }, [])

    const [ssn, setssn] = useState("")
    const [aoc, setaoc] = useState("")
    const [ein, setein] = useState("")







    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpen(true);
        const formData = new FormData();
        formData.append("username", name);
        formData.append("type", "Vendor");
        formData.append("file", file);
        formData.append("identity_doc", icfile);
        formData.append("ein", ein)
        formData.append("aob", aoc)

        formData.append("phone", phone);
        formData.append("gender", gender)
        formData.append("business_documents", maindoc)
        formData.append("business_alternative_document", alternatedoc)
        formData.append("business_country", selectcountry)

        //businessdetail

        formData.append("business_type", businesstype)
        formData.append("business_owner_fn", firstname)
        formData.append("business_owner_ln", lastname)
        formData.append("business_owner_email", email)
        formData.append("business_address", address)
        formData.append("business_registered_address", registeraddress)

        //bank detail

        formData.append("bank_details[acn]", accountNumber);
        formData.append("bank_details[bn]", bankname);
        formData.append("bank_details[ifsc]", ifscCode);
        formData.append("bank_details[ahn]", accountHolderName);

        formData.append("is_active", false);
        formData.append("is_email_verified", false);

        formData.append("referal_code", `${name}`)






        axios
            .post(`${BaseUrl}/store_user`, formData, {
                headers: headers,
            })
            .then((response) => {
                // Handle successful response
                console.log(response.data);
                if (response.data.status == "OK") {
                    toast.success("Data Submit Successfully");

                    setOpen(false);
                    navigate(`/email-verification/${name}/${response.data._id}`)
                }
            })
            .catch((error) => {
                // Handle error
                console.error(error);
                toast.error("data not Submit");
                setOpen(false);
            });
    };
    const handlefile = (e) => {
        let selectedfile = e.target.files[0]
        setfile(selectedfile)

    }
    const handleic = (e) => {
        let selectedfile = e.target.files[0]
        seticfile(selectedfile)
    }


    const handlemaindocument = (e) => {
        let selectedfile = e.target.files[0]
        setmaindoc(selectedfile)
    }

    const handlealternatedocument = (e) => {
        let selectedfile = e.target.files[0]
        setalternatedoc(selectedfile)
    }



    const handlesameaddrss = (e) => {
        let value = e.target.checked
        if (value) {
            setregisteraddress(address)
        } else {
            setregisteraddress("")

        }
    }


    return (
        <>
            <Loader open={open} />
            {/* <Alert show={msgbox} title={title} color={color} /> */}
            <section
                className="bg-white d-flex align-items-center justify-content-center py-3"

            >
                <Container>

                    <Row className="align-items-center">
                        {/* <Col md="5">
                            <div className="text-center">
                                <img src={register} className="img-fluid" alt="" />
                            </div>
                        </Col> */}
                        <Col md="12">
                            <div className=" justify-content-center  w-100  align-items-center registrationpage">
                                <div
                                    className=" shadow py-4"
                                    style={{ borderRadius: "20px", background: "#dcd4fd" }}
                                >

                                    <div className="text-center">
                                        <img src={logo} style={{ width: "250px" }} className="img-fluid" alt="" />
                                    </div>
                                    <Form className="p-md-5 p-3" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Userame <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        className="form-control rounded-pill shadow"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Mobile Number <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        required
                                                        className="form-control rounded-pill shadow"
                                                        value={phone}
                                                        onChange={(e) => setphone(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Gender <span className="text-danger">*</span>
                                                    </label>
                                                    <select
                                                        name=""
                                                        id=""
                                                        value={gender}
                                                        required
                                                        onChange={(e) => setgender(e.target.value)}
                                                        className="form-control rounded-pill shadow form-select"
                                                    >
                                                        <option value="">Select a Gender ?</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Profile Image <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        required
                                                        className="form-control rounded-pill shadow"
                                                        onChange={(e) => handlefile(e)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Select Country <span className="text-danger">*</span>
                                                    </label>
                                                    <select name="" value={selectcountry} onChange={(e) => setselectcountry(e.target.value)} id="" className="form-control form-select rounded-pill">
                                                        <option value="">--Select--</option>
                                                        {country.map((item) => {
                                                            return (
                                                                <option value={item.name}>{item.name}</option>

                                                            )
                                                        })}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">Ideintity Certificate</label>
                                                    <input type="file" className="form-control rounded-pill" onChange={handleic} />
                                                </div>
                                            </div>



                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">Employer Identification Number (EIN)</label>
                                                    <input type="text" className="form-control rounded-pill" value={ein} onChange={(e) => setein(e.target.value)} placeholder="EIN" />

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">AOC</label>
                                                    <input type="text" className="form-control rounded-pill" value={aoc} onChange={(e) => setaoc(e.target.value)} placeholder="AOC" />

                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Business Document <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        required
                                                        className="form-control rounded-pill shadow"
                                                        onChange={(e) => handlemaindocument(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">
                                                        Business Document   <span className="text-danger">(*Alternate)</span>
                                                    </label>
                                                    <input
                                                        type="file"

                                                        className="form-control rounded-pill shadow"
                                                        onChange={(e) => handlealternatedocument(e)}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">GST</label>
                                                    <input type="text" className="form-control rounded-pill" placeholder="GST" />

                                                </div>
                                            </div> */}
                                            <div className="col-md-12">
                                                <h3 style={{ color: "#7208b1" }} className="mb-3">Business Detail</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">Business Type</label>
                                                    <select name="" value={businesstype} onChange={(e) => setbusinesstype(e.target.value)} className="form-control form-select rounded-pill" id="">
                                                        <option value="">--Select--</option>
                                                        <option value="B2C">Business-to-Consumer (B2C)</option>
                                                        <option value="B2B">Business-to-Business (B2B)</option>
                                                        <option value="B2G">Business-to-Government (B2G)</option>
                                                        <option value="C2C">Consumer-to-Consumer (C2C)</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">First Name</label>
                                                    <input type="text" value={firstname} onChange={(e) => setfirstname(e.target.value)} className="form-control rounded-pill" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">Last Name</label>
                                                    <input type="text" value={lastname} onChange={(e) => setlastname(e.target.value)} className="form-control rounded-pill" />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group mb-4">
                                                    <label htmlFor="" className="fw-bold">Email</label>
                                                    <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className="form-control rounded-pill" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="" className="fw-bold">Address</label>
                                                        <textarea name="" value={address} onChange={(e) => setaddress(e.target.value)} id="" className="form-control"></textarea>
                                                        <div className="mt-2">
                                                            <input type="checkbox" onChange={(e) => handlesameaddrss(e)} className="pe-2" id="sameaddress" />
                                                            <label htmlFor="sameaddress" style={{ fontSize: "12px" }} className="fw-bold">If your Address and Registered Address are the same, then click the checkbox.</label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-4">
                                                        <label htmlFor="" className="fw-bold">Registered Address</label>
                                                        <textarea name="" id="" value={registeraddress} onChange={(e) => setregisteraddress(e.target.value)} className="form-control"></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <h3 style={{ color: "#7208b1" }} className="mb-3">Bank Detail</h3>
                                            </div>



                                            <Col md="4">
                                                <FormGroup className="mb-3 ">
                                                    <label htmlFor="acn" className="fw-bold">
                                                        Account Number:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-pill"
                                                        id="acn"
                                                        name="acn"
                                                        value={accountNumber}
                                                        onChange={(e) =>
                                                            setaccountNumber(e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3 ">
                                                    <label htmlFor="bn" className="fw-bold">
                                                        Bank Name:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-pill"
                                                        id="bn"
                                                        name="bn"
                                                        value={bankname}
                                                        onChange={(e) =>
                                                            setbankname(e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3 ">
                                                    <label htmlFor="ifsc" className="fw-bold">
                                                        Routing Number:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-pill"
                                                        id="ifsc"
                                                        name="ifsc"
                                                        value={ifscCode}
                                                        onChange={(e) =>
                                                            setifscCode(e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="4">
                                                <FormGroup className="mb-3 ">
                                                    <label htmlFor="ahn" className="fw-bold">
                                                        Account Holder Name:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-pill"
                                                        id="ahn"
                                                        name="ahn"
                                                        value={accountHolderName}
                                                        onChange={(e) =>
                                                            setaccountHolderName(e.target.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </div>
                                        <div className="">
                                            <button className="btn bg-dark text-white w-100 t-12 text-white rounded-pill px-5 mt-3">
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container >


            </section >
        </>
    );
}

export default Registration;











